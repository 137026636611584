<template>
  <div class="relative w-full font-Gilroy text-black">
    <Header />
    <div id="section-video" class="relative">
      <div class="sleep-wrapper">
        <div class="sleep-layer"></div>
        <video class="sleep-video" autoplay loop muted>
          <source
            :src="require('@/assets/videos/texperia-metaverse.mp4')"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
    <div id="metaverse-intro" class="relative">
      <img :src="require('@/assets/images/marketing/metaverse-intro.png')" />
    </div>
    <div
      class="bg-white rounded-3xl relative p-10 m-10 flex flex-col gap-8 justify-center items-center"
    >
      <img
        :src="require('@/assets/images/marketing/building-workplace.png')"
        class="w-2/3"
      />
      <div class="text-lg w-4/5">
        With the Texperia metaverse, you can leapfrog your organization's
        presence and participation with the tools of tomorrow. Immerse your
        candidates and employees in a magical, virtual environment in which they
        can learn, engage, play, interact and collaborate.
      </div>
    </div>
    <div
      class="green-bg rounded-3xl relative p-10 m-10 flex gap-8 justify-center"
    >
      <div class="mt-10">
        <div class="flex">
          <p
            class="texperia-underline tracking-wider font-Poppins font-bold text-2xl"
          >
            Customized Experiences
          </p>
        </div>
        <div class="text-xl mt-6 w-3/4 leading-10">
          The Texperia Metaverse can be customized with experiences relevant to
          your organization. In this virtual space, users get to connect with
          your brand and messaging from the time they accept your offer, at
          their first day at work or even as an employee for the next 90 days.
        </div>
      </div>
      <img
        :src="require('@/assets/images/marketing/meta-customized-exps.png')"
        class="w-2/5"
      />
    </div>
    <div
      class="yellow-bg rounded-3xl relative p-10 m-10 flex gap-8 justify-center"
    >
      <div class="mt-10">
        <div class="flex">
          <p
            class="texperia-underline tracking-wider font-Poppins font-bold text-2xl"
          >
            Devices
          </p>
        </div>
        <div class="text-xl mt-6 w-3/4 leading-10">
          The Texperia Metaverse is browser compatible and works on all modern
          browsers on desktops, laptops and even mobile.<br /><br />
          Candidates and employees can experience metaverse on any device of
          their choice.
        </div>
      </div>
      <img
        :src="require('@/assets/images/marketing/meta-devices.png')"
        class="w-2/5"
      />
    </div>
    <div
      class="blue-bg rounded-3xl relative p-10 m-10 flex gap-8 justify-center"
    >
      <div class="mt-10">
        <div class="flex">
          <p
            class="texperia-underline tracking-wider font-Poppins font-bold text-2xl"
          >
            Possibilities
          </p>
        </div>
        <div class="text-xl mt-6 w-3/4 leading-10">
          Going forward, you could even create and host events in the Texperia
          metaverse. <br /><br />Invite your candidates and employees to engage,
          immerse and simply get blown away with the experience
        </div>
      </div>
      <img
        :src="require('@/assets/images/marketing/meta-possibilities.png')"
        class="w-2/5"
      />
    </div>
    <div
      class="pink-bg rounded-3xl relative p-10 m-10 flex gap-8 justify-center"
    >
      <div class="mt-10">
        <div class="flex">
          <p
            class="texperia-underline tracking-wider font-Poppins font-bold text-2xl"
          >
            Benefits
          </p>
        </div>
        <div class="text-xl mt-6 w-3/4 leading-10">
          <ul class="list-disc list-outside ml-6">
            <li>
              Engage candidates and employees from day 1, boosting connection
              and productivity.
            </li>
            <li>
              Revolutionize the way they socialize with their colleagues or an
              assigned buddy.
            </li>
            <li>Upgrade to a new era of engagement and communication.</li>
            <li>
              Redefine work for the post-pandemic era & be part of the next step
              in the future of work.
            </li>
          </ul>
        </div>
      </div>
      <img
        :src="require('@/assets/images/marketing/meta-benefits.png')"
        class="w-2/5"
      />
    </div>
    <div
      class="bg-white rounded-3xl relative m-10 flex flex-col gap-8 justify-center items-center"
    >
      <div
        class="text-mColorBlue font-GilroyBlack text-5xl tracking-wide leading-snug mt-10"
      >
        Current Status
      </div>
      <div class="text-lg w-4/5">
        We are getting ready to launch our beta and allow free usage for the
        first 10 enterprise customers. If you want to take a look and be a beta
        user, go ahead and book a demo. Do mention the metaverse in the comments
        so we can get straight to it.
      </div>
      <router-link
        :to="{
          name: 'Contact',
        }"
      >
        <img
          :src="require('@/assets/images/marketing/button-book-demo.png')"
          class="h-12"
        />
      </router-link>
      <div id="demo-banner" class="overflow-hidden">
        <img
          :src="require('@/assets/images/marketing/banner-demo.png')"
          class="w-full transform scale-150 mt-20 mb-10"
        />
      </div>
      <div class="flex gap-10">
        <img
          :src="require('@/assets/images/marketing/meta-screenshot-1.png')"
        />
        <img
          :src="require('@/assets/images/marketing/meta-screenshot-2.png')"
        />
      </div>
      <img
        :src="require('@/assets/images/marketing/meta-book-demo.png')"
        class="w-4/5"
      />
      <hr class="w-full border-0.5 border-gray-300" />
      <img :src="require('@/assets/images/marketing/meta-xr-startup.png')" />
      <Footer class="w-full" />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Marketing/Desktop/Common/Header.vue";
import Footer from "@/components/Marketing/Desktop/Common/Footer.vue";

export default {
  name: "About",
  components: { Header, Footer },
  data() {
    return {};
  },
  created() {},
  updated() {},
  methods: {},
};
</script>
<style scoped>
.journey {
  background: #8bedbe;
  background-image: url("~@/assets/images/marketing/about-journey.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 75% 25%;
}
.texperia-underline {
  position: relative;
}
.texperia-underline::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 5px;
  border-radius: 2px;
  background: linear-gradient(
    45deg,
    #11bc1d 10%,
    #3d50e0 50%,
    #fdb400 75%,
    #ff5670 100%
  );
}
.sleep-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.sleep-video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.sleep-layer {
  background: linear-gradient(45deg, #8a5e85 100%, #c7c7c7 100%);
  opacity: 60%;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.green-bg {
  background: #cafbe9;
}
.yellow-bg {
  background: #ffe8bb;
}
.blue-bg {
  background: #d5f1fc;
}
.pink-bg {
  background: #fbe8e8;
}
</style>
